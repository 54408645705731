<script lang="ts">
  // Stores
  import { setupContext } from '$lib/utils/slider-store';
  // Components
  import { Grid } from '$lib/components/organisms/Layout';
  import SliderNavigation from './SliderNavigation.svelte';

  setupContext();
</script>

<section class="slider">
  <slot />
  <Grid class="slider-navigation-container" noVerticalGap>
    <SliderNavigation on:changeSlide />
  </Grid>
</section>

<style type="text/scss" lang="scss" scoped>
  .slider {
    @apply grid grid-cols-1;
    grid-template-rows: auto theme('spacing.14');

    @screen md {
      grid-template-rows: auto theme('spacing.24');
    }
  }

  :global(.slider > .slider-slide) {
    @apply row-start-1 row-end-3;
    @apply col-start-1 col-end-2;
  }

  :global(.slider > .slider-navigation-container) {
    @apply row-start-2 row-end-3;
    @apply col-start-1 col-end-2;
    @apply flex;
  }
</style>
