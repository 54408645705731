<script lang="ts">
  import { createEventDispatcher, onDestroy } from 'svelte';
  // Stores
  import { getSliderContext } from '$lib/utils/slider-store';

  const dispatch = createEventDispatcher();

  let interval: number | undefined;
  let currentProgress = 0;
  let currentSlideIndex: number | undefined;

  const { currentSlide, slideList, forProductBanner, selectSlideAt } = getSliderContext();

  $: if (currentProgress === 100) {
    clearInterval(interval);

    const nextSlideIndex = currentSlideIndex + 1 === $slideList.length ? 0 : currentSlideIndex + 1;
    selectSlideAt(nextSlideIndex);
    dispatch('changeSlide', nextSlideIndex);
  }

  const restartTimer = () => {
    currentProgress = 0;
    clearInterval(interval);

    interval = setInterval(() => {
      currentProgress += 0.25;
    }, 35);
  };

  const onSegmentClick = (index: number) => {
    selectSlideAt(index);
    dispatch('changeSlide', index);
  };

  currentSlide.subscribe(() => {
    currentSlideIndex = $slideList.indexOf($currentSlide);
    restartTimer();
  });

  onDestroy(() => {
    clearInterval(interval);
  });

  let dark = $currentSlide.type === 'dark';
  let fullDark = $currentSlide.type === 'full-dark';
</script>

<nav
  class="slider-navigation"
  class:slider-navigation-product-banner={forProductBanner}
  class:v-dark={dark}
  class:v-full-dark={fullDark}
>
  {#each Array($slideList.length) as _, i}
    <button
      class="slider-navigation-segment"
      type="button"
      aria-current={currentSlideIndex === i ? 'true' : undefined}
      aria-label="Slide {i + 1}"
      on:click={() => onSegmentClick(i)}
    >
      <div
        class="slider-navigation-progress"
        type="button"
        role="progressbar"
        aria-valuenow={currentSlideIndex === i ? currentProgress : 0}
        aria-valuemin={0}
        aria-valuemax={100}
        style="transform: scaleX({(currentSlideIndex === i ? currentProgress : 0) / 100})"
      />
    </button>
  {/each}
</nav>

<style type="text/scss" lang="scss" scoped>
  .slider-navigation {
    padding-top: 1rem;
    @apply col-span-full;
    @apply flex items-end space-x-1;
    @apply my-auto z-3;
    @apply h-10;

    &.v-dark {
      .slider-navigation-segment {
        &[aria-current] {
          @apply md:bg-opacity-30;
        }
      }
      .slider-navigation-progress {
        @apply md:bg-white;
      }
    }

    &.v-full-dark {
      .slider-navigation-segment {
        &[aria-current] {
          @apply bg-opacity-30;
        }
      }
      .slider-navigation-progress {
        @apply bg-white;
      }
    }
  }

  .slider-navigation-segment {
    @apply flex my-auto;
    @apply w-11 h-0.5;
    @apply bg-gray-300 bg-opacity-30;
    @apply transition-transform duration-300 ease-in-out;
    @apply transform hover:scale-y-300;
    @apply md:w-24 md:h-1;
    @apply outline-none;
    &[aria-current] {
      @apply bg-opacity-100;
      @apply delay-1000;

      .slider-navigation-progress {
        @apply origin-left;
      }
    }
  }

  .slider-navigation-progress {
    @apply appearance-none;
    @apply bg-petrol;
    @apply h-full w-full;
    @apply origin-right;
    @apply transition-transform ease-in-out duration-150;
  }
</style>
