<script lang="ts">
  import classnames from 'classnames';
  // Components
  import { Grid } from "$lib/components/organisms/Layout";

  export let backgroundImage;
  export let cols = 4;
</script>

<section
  style="background-image: url({backgroundImage})"
  class={classnames(
    'mosaic-course-type-modules',

    $$props.class
  )}
>
  <Grid noGrid>
    <div
      class={classnames('mosaic-course-type-modules-wrapper', {
        'custom-cols-4': cols === 4,
        'custom-cols-6': cols === 6,
      })}
    >
      <slot />
    </div>
  </Grid>
</section>

<style type="text/scss" lang="scss" scoped>
  .mosaic-course-type-modules {
    @apply bg-no-repeat bg-cover;
  }

  :global(.custom-cols-6) {
    @apply gap-10 md:gap-20;
    @apply grid grid-cols-1;
    @apply md:grid-cols-4 xl:grid-cols-6;
  }

  :global(.custom-cols-4) {
    @apply gap-10 md:gap-20;
    @apply grid grid-cols-1;
    @apply md:grid-cols-4;
  }

  .mosaic-course-type-modules-wrapper {
    @apply xl:px-0 px-8 md:py-20 p-14;
    @apply overflow-x-auto;
  }
</style>
