<script lang="ts">
  export let square = false
  export let color: "light" | "white" | "dark" | "gold" = "light"
</script>

<div
  class="mosaic-slot"
  class:v-square="{square}"
  class:c-white="{color === 'white'}"
  class:c-light="{color === 'light'}"
  class:c-gold="{color === 'gold'}"
  class:c-dark="{color === 'dark'}"
>
  <div class="mosaic-slot-content">
    {#if $$slots["kpi"]}
      <span class="mosaic-slot-kpi">
        <slot name="kpi" />
      </span>
    {/if}
    <span class="mosaic-slot-text">
      <slot />
    </span>
  </div>
</div>

<style type="text/scss" lang="scss" scoped>
  .mosaic-slot {
    @apply flex;

    &.c-white {
      @apply bg-white;
    }
    &.c-light {
      @apply bg-gray-100;
    }
    &.c-gold {
      @apply bg-brand;
    }
    &.c-dark {
      @apply bg-petrol;
      @apply text-white;

      .mosaic-slot-text {
        @apply text-opacity-60;
      }
    }

    &.v-square {
      @apply items-center justify-center text-center;

      .mosaic-slot-content {
        @apply p-6 md:p-16;
      }

      &::before {
        content: "";
        @apply inline-block;
        @apply w-0 h-0;
        @apply pb-full;
      }
    }
  }

  .mosaic-slot-content {
    @apply flex flex-col;
    @apply flex-1;
    @apply justify-center;
    @apply space-y-2;
    @apply px-4 py-5 md:p-10;
  }

  .mosaic-slot-kpi {
    @apply font-serif font-medium;
    @apply text-5xl md:text-8xl;
  }
</style>
