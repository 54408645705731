import { setContext, getContext, onDestroy } from "svelte"
import { writable, get } from "svelte/store"
import type { SlideIdentifier, SliderContext } from "./types"

const slideList = writable<SlideIdentifier[]>([])
const currentSlide = writable<SlideIdentifier | undefined>(undefined)

const setupContext = (forProductBanner: boolean = false) =>
  setContext<SliderContext>("SLIDER", {
    slideList,
    currentSlide,
    forProductBanner,
    registerSlide: slide => {
      slideList.update(slides => [...slides, slide])
      currentSlide.update(current => current ?? slide)

      onDestroy(() => {
        slideList.update(slides => {
          const newSlides = [...slides]
          const i = slides.indexOf(slide)
          newSlides.splice(i, 1)
          return newSlides
        })
        currentSlide.update(current => {
          const slides = get(slideList)
          return current === slide ? slides[0] : current
        })
      })
    },
    selectSlideAt: (index: number) => {
      currentSlide.set(get(slideList)[index])
    },
  })

const getSliderContext = () => getContext<SliderContext>("SLIDER")

export { setupContext, getSliderContext }
