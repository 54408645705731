<script lang="ts">
  import { Head } from "$lib/components/atoms/Head";
	import { HomepageSlides } from "$lib/components/organisms/Hero";
	import { Mosaic, MosaicCourseTypeModules, Partners, PromoHook, Section } from "$lib/components/organisms/Section";
	import { Tabs, TabList, Tab, TabPanel } from "$lib/components/molecules/Tabs";
	import { ArticleCard, CourseReviewCard, CourseTypeModuleCard, EventCard } from "$lib/components/organisms/Card";
	import { Grid, ScrollableCardWrapper } from "$lib/components/organisms/Layout";

  import type { PageData } from "./$types";
  import type { Entry } from 'contentful';
  import type { ICourseSlide, IHomepageSlides, INews } from '$lib/utils/contentful/types';

  import { SettingsUtils } from "$lib/utils/settings-utils";
  import { getCourseByProductCode } from '$lib/utils/course-utils';
	import { useOktaStore } from "$lib/utils/auth-store";
	import { HomePageStore } from "$lib/utils/homepage/store";
  import { GtmStore } from '$lib/utils/gtm-store';
	import { MosaicSlot } from "$lib/components/atoms/MosaicSlot";
	import { LinkTo } from "$lib/components/atoms/Link";
	import { lazyLoader } from "$lib/utils/lazyloader";

  export let data: PageData;
  const { 
    settings, 
    homepageSlidesWithoutBsids, 
    homepageSlidesWithBsids, 
    allInitiatives,
    metaTagData
  } = data;

  const authStore = useOktaStore();
  const isLoggedIn = authStore.isLoggedIn;
  const userInfo = authStore.userInfo;

  let bsid: string | null = $isLoggedIn && $userInfo?.bsid ? $userInfo.bsid : null;;

  try {
    SettingsUtils.loadPreloadedSettings(settings);
  } catch (e) {
    console.log('Warning, settings');
  }
  const { trendCourses, aboutUsReviews, promoBox } = HomePageStore.getInstance();

  const news: Entry<INews>[] = settings.homePageSettingsData.news;

  let hasCustomizedProductSlides = false;

  const switchProductSlides = async (): Promise<IHomepageSlides[]> => {
    hasCustomizedProductSlides = false;

    //IF there's a slide for a product nothing to do, just return the original list
    for (let singleSlide of homepageSlidesWithoutBsids){
      if (singleSlide.productCode){
        return homepageSlidesWithoutBsids;
      }
    }

    //IF not let's check if there's a slide for the user's bsid
    if (bsid){
      let foundProductSlide: ICourseSlide | null = null;
      //IF THERE AREN'T bsids product slide just return the initial list without bsid
      if (!homepageSlidesWithBsids) {
        return homepageSlidesWithoutBsids;
      } else {
        for (let productSlide of homepageSlidesWithBsids) {
          if (productSlide.productCode
              && productSlide.bsids
              && productSlide.bsids.content
              && productSlide.bsids.content[0]
              && productSlide.bsids.content[0].content
              && productSlide.bsids.content[0].content[0]
              && productSlide.bsids.content[0].content[0].value
              && productSlide.bsids.content[0].content[0].value.length > 0
              && productSlide.bsids.content[0].content[0].value.includes(bsid)
          ) {
            //get course from productCode
            let courseFromStore = await getCourseByProductCode(productSlide.productCode, fetch);

            if (courseFromStore) {

              foundProductSlide = productSlide;

              const newProductSlide: ICourseSlide = {
                ...foundProductSlide as ICourseSlide,
                course: courseFromStore,
              }

              homepageSlidesWithoutBsids.unshift(newProductSlide);
              hasCustomizedProductSlides = true;
              return homepageSlidesWithoutBsids
            }
          }
        }
      }
    }

    //IN ANY OTHER CASE RETURNS the initial slide list
    return homepageSlidesWithoutBsids;
  }

  const initiatives = allInitiatives
    .filter((initiative) => {
      if(!initiative || !initiative.fields || initiative.fields.date){
        return false;
      }
      const today = new Date().getTime();
      const date = new Date(initiative.fields.date).getTime();
      if (date - today >= 0){
        return true;
      }
      else {
        return false;
      }
    })
    .sort((a, b) => {
      const dateA = new Date(a.fields.date).getTime();
      const dateB = new Date(b.fields.date).getTime();
      return dateA - dateB;
    });

  /******************** GTM EVENT PUSH *************************/
  GtmStore.pushEvent({
    pageCategory: 'Home',
    page_path: '/',
  });
  /*************************************************************/

  const seoDetails = {
    title: metaTagData?.fields?.metaTagSocialTitle || '24ORE Business School - MBA, Master Full Time, Part Time ed Executive Master',
    description: metaTagData?.fields?.metaTagSocialDescription || '30 anni di eccellenza nella formazione: 500 Master in 12 settori, per trasformare passioni in successi professionali nel mondo del lavoro.',
    metaTagSocialTitle: metaTagData?.fields?.metaTagSocialTitle || '',
    metaTagSocialDescription: metaTagData?.fields?.metaTagSocialDescription || '',
    url: '/',
    image: metaTagData?.fields?.metaTagSocialImage?.fields.file.url || '',
    metaTagSocialImage: metaTagData?.fields?.metaTagSocialImage?.fields.file.url || '',
    ldScriptTag: '',
  };

</script>

<Head {seoDetails} />

<section class="main">
  {#await switchProductSlides()}
    <div class="hero-loading"></div>
  {:then homepageSlides}
    <HomepageSlides {homepageSlides} hasCustomizedProductSlide="{hasCustomizedProductSlides}" />
  {/await}
  {#if $promoBox}
    <PromoHook data={$promoBox} />
  {/if}

  <Section noPaddingBottom>
    <h2 slot="header" class="fs-2">
      Pianifica la tua <span class="text-brush">carriera</span>
    </h2>
    <Tabs>
      <TabList>
        <Tab>Full TIme</Tab>
        <Tab>Executive</Tab>
        <Tab>MBA</Tab>
        <Tab>Corsi</Tab>
      </TabList>

      <TabPanel>
        <MosaicCourseTypeModules backgroundImage="/assets/images/homepage/hp-master-bg.webp">
          <CourseTypeModuleCard
            courseTypeSlug="master-full-time"
            linkTo="/master-full-time"
            title="MASTER POST LAUREA CON STAGE GARANTITO"
            content="Per giovani laureati. Percorsi di specializzazione per funzione o per  settore, a tempo pieno, con stage garantito e diploma"
          />
          <CourseTypeModuleCard
            courseTypeSlug="mba-full-time"
            linkTo="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
            title="MBA FULL TIME"
            content="Per giovani laureati. Un percorso in business administration con specializzazioni elective e career service dedicato"
          />
        </MosaicCourseTypeModules>
      </TabPanel>
      <TabPanel>
        <MosaicCourseTypeModules backgroundImage="/assets/images/homepage/hp-corsi-bg.webp">
          <CourseTypeModuleCard
            courseTypeSlug="executive-master"
            linkTo="/executive-master"
            title="EXECUTIVE MASTER"
            content="Per acquisire o consolidare competenze indispensabili per crescere professionalmente e accelerare il proprio percorso di carriera"
          />
          <CourseTypeModuleCard
            courseTypeSlug="professional-master"
            linkTo="/professional-master"
            title="PROFESSIONAL MASTER"
            content="Per aggiornare le proprie competenze e rimanere competitivi nel mondo del lavoro"
          />
          <CourseTypeModuleCard
            courseTypeSlug="corsi-executive"
            linkTo="/corsi-executive"
            title="CORSI EXECUTIVE"
            content="Per essere sempre aggiornati  e affrontare le sfide professionali di un mercato  lavorativo in continua evoluzione"
          />
        </MosaicCourseTypeModules>
      </TabPanel>
      <TabPanel>
        <MosaicCourseTypeModules backgroundImage="/assets/images/homepage/hp-corsi-bg.webp">
          <CourseTypeModuleCard
            courseTypeSlug="mba"
            linkTo="/mba"
            title="EXECUTIVE MBA"
            content="Per profili executive che desiderano consolidare competenze manageriali e di leadership e sviluppare una carriera dirigenziale"
          />
          <CourseTypeModuleCard
            courseTypeSlug="mba-full-time"
            linkTo="/formazione/mba-full-time/mba-strategia-innovazione-e-sostenibilita"
            title="MBA FULL TIME"
            content="Per giovani professionisti che mirano a carriera di successo in grandi aziende o società di consulenza"
          />
          <CourseTypeModuleCard
            courseTypeSlug="mba-part-time"
            linkTo="/formazione/mba-part-time/mba-part-time"
            title="MBA PART TIME"
            content="Per giovani professionisti che desiderano accelerare la propria crescita professionale e acquisire competenze strategiche e innovative"
          />
        </MosaicCourseTypeModules>
      </TabPanel>
      <TabPanel>
        <MosaicCourseTypeModules backgroundImage="/assets/images/homepage/hp-corsi-bg.webp">
          <CourseTypeModuleCard
            courseTypeSlug="corsi-online"
            linkTo="/corsi-online"
            title="CORSI ONLINE"
            content="Videolezioni ed esercitazioni per studiare quando vuoi. Podcast e materiali didattici da tenere sempre con te"
          />
          <CourseTypeModuleCard
            courseTypeSlug="master-euroconference"
            linkTo="/master-euroconference"
            title="CORSI EUROCONFERENCE"
            content="Percorsi formativi in partnership con Euroconference su tematiche fiscali, legali e di diritto del lavoro. Per rimanere sempre aggiornati sulle ultime novità e conseguire crediti formativi professionalizzanti"
          />
          <CourseTypeModuleCard
            courseTypeSlug="masterclass"
            linkTo="/masterclass"
            title="MASTERCLASS"
            content="Sessioni formative in Live Streaming, brevi, smart, flessibili e orientate al business,  integrate da presentazioni di casi di successo e best practices da cui trarre spunto"
          />
        </MosaicCourseTypeModules>
      </TabPanel>
    </Tabs>
  </Section>

  {#if $trendCourses.length}
    <Section class="bg-gray-50">
      <h2 slot="header" class="fs-2">
        Segui il <span class="text-brush">trend</span>
      </h2>

      <ScrollableCardWrapper lazyLoad={true} courses={$trendCourses} />
    </Section>
  {/if}

  <Mosaic bgUrl="/assets/placeholders/high/meeting.webp" lockGrid>
    <MosaicSlot color="light">
      <h2>Disegna il tuo <span class="text-brush">futuro</span></h2>
    </MosaicSlot>
    <MosaicSlot color="white" square>
      <span slot="kpi">55%</span>
      <p>dei partecipanti, trova lavoro già durante il Master</p>
    </MosaicSlot>
    <MosaicSlot color="gold" square>
      <p class="fs-3">STAGE</p>
    </MosaicSlot>
    <MosaicSlot color="dark" square>
      <span slot="kpi">90%</span>
      <p>dei partecipanti, trova o cambia lavoro entro 4 mesi dal termine del Master</p>
    </MosaicSlot>
  </Mosaic>

  {#if $aboutUsReviews.length > 0}
    <Section compact class="bg-gray-50">
      <h3 slot="header" class="uppercase font-sans font-semibold tracking-wide text-sm md:text-base text-gray-900">
        Dicono di noi
      </h3>

      <Grid>
        {#each $aboutUsReviews as review}
          <div class="col-span-full md:col-span-6">
            <CourseReviewCard truncateTitle truncateBody {review} />
          </div>
        {/each}
      </Grid>

      <LinkTo slot="footer" href="/reviews">Leggi le review</LinkTo>
    </Section>
  {/if}

  {#if initiatives.length > 0}
    <Section>
      <h2 slot="header" class="fs-2">
        Partecipa ai <span class="text-brush">webinar</span>
      </h2>

      <Grid>
        {#if initiatives[0]}
          <div class="col-span-full md:col-span-6">
            <EventCard event={initiatives[0]} />
          </div>
        {/if}
        {#if initiatives[1]}
          <div class="col-span-full md:col-span-6">
            <EventCard event={initiatives[1]} />
          </div>
        {/if}
      </Grid>
      <LinkTo slot="footer" href="/eventi">Vedi tutte le iniziative</LinkTo>
    </Section>
  {/if}

  {#if news.length > 0}
    <Section class="bg-gray-50">
      <h2 slot="header" class="fs-2">
        Espandi i tuoi <span class="text-brush">orizzonti</span>
      </h2>
      <Grid>
        {#each news as article}
          <div class="col-span-full md:col-span-4">
            <ArticleCard lazyLoad={true} article={article} />
          </div>
        {/each}
      </Grid>
      <LinkTo slot="footer" href="/news">Vedi tutti gli articoli</LinkTo>
    </Section>
  {/if}

  <Section>
    <div class="flex" slot="header">
      <h2 class="caption-title md:mx-auto">I nostri partner</h2>
    </div>
    <Partners>
      <img
        use:lazyLoader={"/assets/placeholders/mobile/partners/n26.webp"}
        srcset="/assets/placeholders/desktop/partners/n26.webp 1x, /assets/placeholders/high/partners/n26.webp 2x"
        alt="N26"
      />
      <img
        use:lazyLoader={"/assets/placeholders/mobile/partners/accenture.webp"}
        srcset="/assets/placeholders/desktop/partners/accenture.webp 1x, /assets/placeholders/high/partners/accenture.webp 2x"
        alt="accenture"
      />
      <img
        use:lazyLoader={"/assets/placeholders/mobile/partners/bmw.webp"}
        srcset="/assets/placeholders/desktop/partners/bmw.webp 1x, /assets/placeholders/high/partners/bmw.webp 2x"
        alt="BMW"
      />
      <img
        use:lazyLoader={"/assets/placeholders/mobile/partners/samsung.webp"}
        srcset="/assets/placeholders/desktop/partners/samsung.webp 1x, /assets/placeholders/high/partners/samsung.webp 2x"
        alt="Samsung"
      />
      <img
        use:lazyLoader={"/assets/placeholders/mobile/partners/enel.webp"}
        srcset="/assets/placeholders/desktop/partners/enel.webp 1x, /assets/placeholders/high/partners/enel.webp 2x"
        alt="Enel"
      />
      <img
        use:lazyLoader={"/assets/placeholders/mobile/partners/booking.webp"}
        srcset="/assets/placeholders/desktop/partners/booking.webp 1x, /assets/placeholders/high/partners/booking.webp 2x"
        alt="booking.com"
      />
      <img
        use:lazyLoader={"/assets/placeholders/mobile/partners/mediolanum.webp"}
        srcset="/assets/placeholders/desktop/partners/mediolanum.webp 1x, /assets/placeholders/high/partners/mediolanum.webp 2x"
        alt="Mediolanum"
      />
      <img
        use:lazyLoader={"/assets/placeholders/mobile/partners/sky.webp"}
        srcset="/assets/placeholders/desktop/partners/sky.webp 1x, /assets/placeholders/high/partners/sky.webp 2x"
        alt="Sky"
      />
    </Partners>

    <LinkTo slot="footer" href="/partners">Vedi tutti i partner</LinkTo>
  </Section>
</section>

<style type="text/scss" lang="scss" scoped>
  .hero-loading{
    @apply md:h-[400px] h-[550px] w-full;
  }
</style>