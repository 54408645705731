<script lang="ts">
  // Stores
  import { CourseTypeStore } from '$lib/utils/courses/type-store';
  // Components
  import { Icon } from '$lib/components/atoms/Icon';
  import { Brush } from '$lib/components/atoms/Brush';

  export let title = 'Title of the card';
  export let content = 'Card Content';
  export let courseTypeSlug: string;

  export let linkTo = '/master-online';

  const courseTypeClass = CourseTypeStore.getCourseTypeCssClassByCourseType(CourseTypeStore.getCourseTypeByCourseTypeSlug(courseTypeSlug));
</script>

<a class="course-type-module-card {courseTypeClass}" href={linkTo}>
  <div class="course-type-module-wrapper">
    <h4 class="course-type-module-title">{title}</h4>
    <div class="course-type-module-brush">
      <Brush />
    </div>

    <h6 class="course-type-module-content">{content}</h6>
  </div>
  <span class="course-type-module-icon">
    <Icon icon="chevron-right" />
  </span>
</a>

<style type="text/scss" lang="scss" scoped>
  .course-type-module-card {
    min-height: 140px;
    @apply rounded bg-white shadow-md;
    @apply col-span-2;
    @apply relative;

    &:hover {
      @apply cursor-pointer;

      .course-type-module-icon {
        right: 0.4rem;
        filter: brightness(50%);
      }
    }
  }

  .course-type-module-wrapper {
    @apply p-4 pb-6 md:p-6 md:pb-8;
  }

  .course-type-module-title {
    @apply font-sans font-semibold;
    @apply text-sm md:text-base uppercase tracking-wider text-tint;
    @apply mb-0;
  }

  .course-type-module-content {
    @apply font-serif font-medium;
    @apply text-md md:text-lg;
  }

  .course-type-module-brush {
    @apply -mt-3;
  }
  .course-type-module-icon {
    transition: right 100ms;
    @apply absolute bottom-2.5 right-2.5;
  }
</style>
