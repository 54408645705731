<script lang="ts">
  import classnames from "classnames"
  import { omit } from "$lib/utils/object"
  // Components
  import { Icon } from '$lib/components/atoms/Icon';
  // import { Modal } from '$lib/components/organisms/Modal';

  // Default Props
  export let color: "dark" | "light" = "dark";
  /** -- */
  export let href = "#";
  /* Expand Target Area with pseudo-selector */
  export let expanded = false;

  export let videoSrc: string = null;
  export let videoType: string = null;

  let open = false;

  const classNames = classnames($$props.class, {
    link: true,
    "c-dark": color === "dark",
    "c-light": color === "light",
  })
  const attributes = omit($$restProps, "class")
</script>


<a on:click="{() => (open = true)}" class="{classNames}" {...attributes} class:expanded>
  <span class="link-icon">
    <Icon icon="playVideo" size="full" />
  </span>
  <span class="link-text">
    <slot />
  </span>
</a>

<!-- <Modal bind:open={open} on:close="{() => (open = false)}" showCloseButton centerContent closeOnBackdropClick>
  <video
    controls
    playsinline
    autoplay
    preload="none"
    data-src={videoSrc}
    class="video-modal"
  >
    <source src={videoSrc} type={videoType} />
  </video>
</Modal> -->

<style type="text/scss" lang="scss" scoped>
  .link {
    @apply appearance-none;
    @apply inline-flex flex-shrink-0 items-center self-center;
    @apply uppercase font-semibold;
    @apply text-sm whitespace-nowrap no-underline tracking-wide;
    @apply border-none;
    @apply p-0;
    @apply select-none;
    @apply transition-colors ease-in-out duration-300;

    &:hover {
      .link-icon {
        @apply transform scale-110;
      }
    }

    &.expanded {
      &:before {
        content: "";
        @apply block;
        @apply md:absolute inset-0;
      }
    }
  }

  /* ICON  */

  .link-icon {
    @apply inline-flex;
    @apply w-12 h-12;
    @apply transition-transform ease-in-out duration-300;
  }

  .link-icon + .link-text:not(:empty) {
    @apply ml-3;
  }

  /* COLORS */
  .c-dark {
    @apply text-gray-900;
    /* Hover */
    @apply hover:text-petrol;
  }

  .c-light {
    @apply text-white;
  }
  .link-text {
    @apply sr-only md:not-sr-only;
  }
</style>
