<script lang="ts">
  // Components
  import { Grid } from "$lib/components/organisms/Layout";

  export let bgUrl: string
  export let lockGrid = false
</script>

<section class="mosaic" class:v-grid="{lockGrid}">
  {#if lockGrid}
    <Grid class="mosaic-inner-grid" noHorizontalPadding noHorizontalGap noVerticalGap>
      <slot />
    </Grid>
  {:else}
    <slot />
  {/if}
  <div class="mosaic-bg" style="background-image: url({bgUrl})"></div>
</section>

<style type="text/scss" lang="scss" scoped>
  .mosaic {
    @apply grid grid-cols-2 grid-flow-row-dense;
    @apply lg:grid-cols-4;

    &.v-grid {
      @apply lg:grid-cols-1 lg:grid-rows-1;

      .mosaic-bg {
        grid-row: 1 / 100;
        grid-column: 1 / -1;
      }
    }
  }

  .mosaic > :global(.mosaic-inner-grid) {
    @apply contents;
    @apply row-span-6;
    @apply col-span-1;
    @apply grid-cols-2 grid-flow-row-dense;
    @apply lg:row-start-1 lg:col-start-1;
    @apply lg:grid lg:grid-cols-4 lg:grid-flow-row-dense;
    @apply 2xl:page-margin;
  }

  :global(.mosaic > :nth-child(1)),
  :global(.mosaic-inner-grid > :nth-child(1)) {
    @apply row-start-1;
    @apply col-start-1 col-span-2;
    @apply lg:row-start-1;
    @apply lg:col-start-1 lg:col-span-2;
  }
  :global(.mosaic > :nth-child(2)),
  :global(.mosaic-inner-grid > :nth-child(2)) {
    @apply row-start-2;
    @apply col-start-1 col-span-1;
    @apply lg:row-start-2;
    @apply lg:col-start-1 lg:col-span-1;
  }
  :global(.mosaic > :nth-child(3)),
  :global(.mosaic-inner-grid > :nth-child(3)) {
    @apply row-start-3;
    @apply col-start-2 col-span-1;
    @apply lg:row-start-2;
    @apply lg:col-start-3 lg:col-span-1;
  }
  :global(.mosaic > :nth-child(4)),
  :global(.mosaic-inner-grid > :nth-child(4)) {
    @apply row-start-4;
    @apply col-start-1 col-span-1;
    @apply lg:row-start-1;
    @apply lg:col-start-4 lg:col-span-1;
  }
  :global(.mosaic > :nth-child(5)),
  :global(.mosaic-inner-grid > :nth-child(5)) {
    @apply row-start-5;
    @apply col-start-2 col-span-1;
    @apply lg:row-start-3;
    @apply lg:col-start-4 lg:col-span-1;
  }

  .mosaic-bg {
    @apply row-start-1 row-end-7;
    @apply col-span-full;
    @apply bg-cover bg-center bg-no-repeat;
    @apply -z-1;
  }
</style>
