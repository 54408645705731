<script lang="ts">
  // Components
  import { Grid } from '$lib/components/organisms/Layout';

  export let imageSrc: string;
  export let srcset: string = imageSrc;
  export let imageAlt: string;
  export let imageFullWidth: boolean = false;
  export let videoSrc: string = null;
  export let videoType: string = null;
  export let imageContain: boolean = false;
  export let type: 'light' | 'dark' | 'darkEvo' = 'light';

  let hasLoadedImage = false;

  const onload = (el: HTMLImageElement) => {
    if (el.complete) hasLoadedImage = true;

    el.onload = () => (hasLoadedImage = true);
  };
</script>

<section
  class="hero"
  class:v-contain={imageContain}
  class:v-bg-preview={!hasLoadedImage}
  class:v-full={imageFullWidth}
  class:v-dark={type === 'dark'}
  class:v-dark-evo={type === 'darkEvo'}
>
  <Grid class="grid-hero" noVerticalGap noHorizontalGap noHorizontalPadding>
    <div class="hero-content">
      <div class="hero-title">
        <slot name="title" />
      </div>
      <div class="hero-text">
        <slot name="text" />
      </div>
      {#if $$slots['cta'] || $$slots['cta-play']}
        <div class="hero-content-action">
          <div class="hero-cta-list">
            <div class="hero-cta">
              <slot name="cta" />
            </div>
            <div class="hero-cta-play">
              <slot name="cta-play" />
            </div>
          </div>
        </div>
      {/if}
    </div>
    {#if videoSrc}
      <video playsinline autoplay muted loop preload="none" data-src={videoSrc} class="hero-video">
        <source src={videoSrc} type={videoType} />
      </video>
    {:else}
      <img src={imageSrc} {srcset} alt={imageAlt} class="hero-image" use:onload />
    {/if}
    {#if $$slots['cta-play']}
      <div class="cta-play-mobile">
        <slot name="cta-play" />
      </div>
    {/if}
  </Grid>
</section>

<style type="text/scss" lang="scss" scoped>
  :global(.grid-hero) {
    @screen lg {
      grid-template-rows: 1fr;
    }
  }

  .hero {
    @apply relative;
    @apply flex;
    @apply md:h-[400px];

    &::before {
      content: '';
      max-height: calc(100vh - 55px);
      @apply inline-block;

      @screen md {
        content: none;
      }
    }

    &.v-bg-preview {
      .hero-image {
        @apply bg-gray-100;
      }
      .hero-video {
        @apply bg-gray-100;
      }

      &.v-dark {
        .hero-image {
          @apply bg-gray-900;
        }
        .hero-video {
          @apply bg-gray-900;
        }
      }
    }

    &.v-dark {
      @apply md:text-white;

      @screen md {
        &::after {
          content: '';
          @apply absolute left-0 inset-y-0;
          @apply w-2/3;
          @apply bg-gradient-focus-to-r;
        }
      }
    }

    &.v-dark-evo {
      @apply text-white;

      &::after {
        content: '';
        @apply absolute bottom-0 inset-x-0;
        @apply h-2/3;
        @apply md:w-2/3 md:h-full;
        @apply bg-gradient-focus-to-t;
        @apply md:left-0 md:inset-y-0;
        @apply md:bg-gradient-focus-to-r;
      }

      .hero-content {
        @apply justify-end;
        @apply md:justify-center;
      }
      .hero-content-action:not([hidden]) {
        @apply mt-5;
        @apply md:mt-7;
      }
      .hero-image {
        @apply absolute top-0 w-full h-full;
      }
      .hero-video {
        @apply absolute top-0 w-full h-full object-cover;
      }
    }

    &.v-contain {
      .hero-image {
        @apply lg:object-contain object-cover;
        @screen md {
          min-height: 100% !important;
        }
      }
      .hero-video {
        @apply lg:object-contain object-cover;
        @screen md {
          min-height: 100% !important;
        }
      }
    }

    &.v-full {
      .hero-content {
        @apply md:col-span-8 lg:col-span-7;
      }
      .hero-image {
        @apply object-top;
        @apply md:absolute md:inset-0;
        @apply md:z-0;
      }
      .hero-video {
        @apply object-top;
        @apply md:absolute md:inset-0;
        @apply md:z-0;
      }
    }
  }

  .cta-play-mobile {
    @apply row-start-1 col-span-full;
    @apply flex justify-center;
    @apply md:hidden;
  }

  .hero-content {
    @apply col-span-full z-1;
    @apply flex flex-col justify-start;
    @apply px-4 py-6 mb-8 space-y-5;
    @apply row-start-2;
    @apply md:row-start-1 md:row-span-2;
    @apply md:justify-center;
    @apply md:col-span-6 lg:col-span-5;
    @apply md:page-margin md:mb-8 md:space-y-7;

    .hero-text {
      @apply max-w-prose;
      @apply leading-relaxed;
    }

    .hero-content-action:not([hidden]) {
      @apply mt-auto;
      @apply md:mt-7;
    }

    .hero-cta-list {
      @apply flex flex-wrap items-center;
      @apply -m-3;

      > * {
        @apply m-3;
      }
    }

    .hero-cta-play {
      @apply hidden;
      @apply md:flex;
    }
  }

  .hero-image {
    @apply col-span-full;
    @apply w-full h-auto object-contain;
    @apply row-start-1;
    @apply md:row-span-2;
    @apply md:col-span-6 lg:col-span-7;
    @apply sm:h-[400px];
  }
  .hero-video {
    @apply col-span-full;
    @apply w-full h-auto object-contain;
    @apply row-start-1;
    @apply md:row-span-2;
    @apply md:col-span-6 lg:col-span-7;
    @apply md:h-full md:object-cover;
  }
</style>
