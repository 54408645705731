<script lang="ts">
  // Stores
  import { getSliderContext } from '$lib/utils/slider-store';

  export let type: 'light' | 'dark' | 'full-dark' = 'light';

  const { currentSlide, registerSlide } = getSliderContext();

  const slideInstance = {
    id: Math.random().toString(36).substring(8), // for debug purpuse
    type,
  };
  registerSlide(slideInstance);

  $: active = $currentSlide === slideInstance;
</script>

<div class="slider-slide" class:active class:v-dark={type === 'full-dark'}>
  <slot />
</div>

<style type="text/scss" lang="scss" scoped>
  .slider-slide {
    @apply opacity-0;
    @apply z-0;
    transition: all 1000ms ease 75ms, height 100ms ease 2s;

    &.active {
      @apply opacity-100;
      @apply z-1;
    }

    &:not(.active) {
      height: 0;
    }

    &.v-dark {
      @apply bg-petrol;
    }
  }
</style>
